import React, { FunctionComponent, ReactNode } from 'react';
import Head from 'next/head';

import Navbar from './navbar';
import Footer from './footer';

type ComponentProps = {
  children?: ReactNode;
};

export const Layout: FunctionComponent<ComponentProps> = ({ children }) => {
  return (
    <>
      <Head>
        <title>Rivul</title>
        <meta name='description' content='Rivul' />
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  );
};
