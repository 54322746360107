import { SendEmailCommand, SendEmailCommandOutput, SESClient } from '@aws-sdk/client-ses';

// IMPORTANT: it needs to be placed outside the Function Handler
const client = new SESClient({
  region: process.env.SL_AWS_REGION, // Change it to match your region
  credentials: {
    accessKeyId: process.env.SL_AWS_ACCESS_KEY_ID!,
    secretAccessKey: process.env.SL_AWS_SECRET_ACCESS_KEY!,
  },
});

export type SendEmailOptions = {
  email: string;
  subject: string;
  htmlMessage: string;
  textMessage: string;
};

export async function sendEmail(email: SendEmailOptions): Promise<SendEmailCommandOutput> {
  const params = {
    Destination: {
      /* required */
      CcAddresses: [
        /* more items */
      ],
      ToAddresses: ['mick@rivul.io'],
    },
    Message: {
      /* required */
      Body: {
        /* required */
        Html: {
          Charset: 'UTF-8',
          Data: email.htmlMessage,
        },
        Text: {
          Charset: 'UTF-8',
          Data: email.textMessage,
        },
      },
      Subject: {
        Charset: 'UTF-8', 
        Data: email.subject,
      },
    },
    Source: 'mick@rivul.io', // SENDER_ADDRESS
    ReplyToAddresses: [
      /* more items */
    ],
  };
  
  return await client.send(new SendEmailCommand(params));
}
